// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.btn-blue{
    text-transform: uppercase;
    border:1px solid #000;
    background:#00b0ed;
    padding: 10px 20px !important;
    border-radius:0;
    color:white;
}



.findProduct{
    background-color:#0886b2;
    .solutions-icons{
        margin:auto;
        max-width:1000px;
        margin-bottom:40px;
        @media screen and (max-width:768px){
            display:none;
        }
        
    }

    .solutions-icons-mobile{
        display:none;
        margin-bottom:40px;
        @media screen and (max-width:768px){
            display:block;
        }
    }
}

.nowrap{
    white-space:nowrap;
}

//products pages
body.products{
    .product{
        .prodImg{
            img{
                max-height:300px;
                object-fit:contain;
            }
        }
    }
}

#resourcesPgTitle{
    text-transform:uppercase;
}

// Solution selector.
.solutions-selector {
    padding: 60px 0;
    text-align: center;

    strong {
        font-family: "gotham_boldregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
    }

    .container {
        max-width: 780px;
    }

    .questions {
        display: flex;
        flex-direction: column;
    }

    .field {
        margin-bottom: 60px;

        .control {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .field-radio {
        input {
            border: 1px solid black;

            &:checked {
                background: #00b0ed;
            }

            &:active,
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .option {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 20px;
        }

        label {
            margin: 4px 0 0 20px;
        }

        @media screen and (max-width: 768px) {
            .control {
                align-items: flex-start;
                flex-direction: column;
            }
            .option {
                margin-bottom: 15px;
            }
        }
    }

    .select-wrapper {
        position: relative;

        &:after {
            top: 20px;
            right: 10px;
            opacity: .75;
            color: #000;
            line-height: 1;
            font-size: 28px;
            content: '\ \f104';
            position: absolute;
            pointer-events: none;
            transform: rotate(-90deg) translateY(-50%);
            font-family: 'FontAwesome';
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        select {
            cursor: pointer;
            min-width: 250px;
        }
    }

    .btn {
        text-transform: uppercase;
        border: 1px solid #000;
        background: #00b0ed;
        border-radius: 0;
    }

    .results-modal {
        z-index: 1000000;
        position: fixed;
        background: #e4e5e9;
        text-align: center;
        pointer-events: none;
        opacity: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

        iframe {
            width: 100% !important;
            min-width: 350px;
        }

        .close {
            position: absolute;
            right: 20px;
            top: 20px;
        }

        h2 {
            margin-bottom: 40px;
        }

        .inner {
            padding: 40px 150px 0px 150px;
        }

        &.active {
            opacity: 1;
            pointer-events: initial;
        }

        @media screen and (max-width: 1024px) {
            max-width: 100%;
            .inner {
                padding: 40px 20px 0px 20px;
            }
        }
    }

    &.results {
        text-align: left;

        .subtitle h3 {
            text-align: center;
            color: #666;
            text-transform: uppercase;
        }

        .section {
            h2 {
                text-transform: uppercase;
            }
        }

        .email {
            text-align: center;
        }

        .charts {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .chart {
                margin-bottom: 40px;
                width: calc((100% - 30px) / 2);
                text-align: center;
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
                // align-items: center;
            }

            @media screen and (max-width: 768px) {
                .chart {
                    width: 100%;
                }
            }
        }

        .product-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .product {
                text-align: center;
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: calc((100% - 30px) / 2);
                margin-bottom: 50px;

                p {
                    max-width: 330px;
                }

                img {
                    max-width: 250px;
                    margin-top: 20px;
                    max-height: 250px;
                    object-fit: contain;
                }
            }

            @media screen and (max-width: 768px) {
                .product {
                    width: 100%;
                }
            }
        }

        // New styles.
        .section-parasites {
            padding-top: 60px;

            h2 {
                font-family: "gotham_mediumregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                color: #04407b;
                font-size: 32px;
                font-weight: 500;
                line-height: 36px;
            }

            h3 {
                color: #222222;
                font-family: "gotham_boldregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;
            }

            ul {
                li {
                    font-family: "gotham_bookregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-left: 20px;
                }
            }
        }

        .section-management {
            padding: 75px 0;

            h2 {
                font-family: "gotham_mediumregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                color: #04407b;
                font-size: 32px;
                font-weight: 500;
                line-height: 36px;
            }

            h3 {
                font-family: "gotham_boldregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;
                color: #222222;
                padding-top: 15px;
                padding-bottom: 30px;
            }

            ul {
                li {
                    font-family: "gotham_bookregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 24px;
                    margin-bottom: 20px;
                    margin-left: 20px;

                    ul {
                        list-style: none;

                        li {
                            position: relative;
                            margin-left: 15px;

                            &:before {
                                content: "-";
                                position: absolute;
                                left: -15px;
                            }
                        }
                    }
                }
            }
        }

        .section-products {
            h2 {
                color: #04407b;
                font-family: "gotham_mediumregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                color: #04407b;
                font-size: 32px;
                font-weight: 500;
                line-height: 36px;
                text-transform: uppercase;
            }

            .product {
                h3 {
                    font-family: "gotham_mediumregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                    color: #04407b;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 700;
                    text-align: center;
                }

                p {
                    /* Style for "Extended-r" */
                    color: #222222;
                    font-family: "gotham_bookregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    text-align: center;
                }
            }
        }

        .section-disclaimers {
            padding-top: 65px;

            p {
                color: #222222;
                font-family: "gotham_bookregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                font-size: 18px;
                line-height: 24px;
            }
        }

        .section-charts {
            h3 {
                font-family: "gotham_boldregular", "Myriad Pro", "Helvetica", "Arial", sans-serif;
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;
                color: #222222;
                margin: 0;
                padding: 0 0 0 20px;
            }
            &.first {
                margin-bottom: -30px;
            }
            &.second {
                margin-bottom: 45px;
                margin-top: -60px;
            }
        }
    }
}